// gatsby-ssr.js and gatsby-browser.js files

import React from "react";
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews";
import Aos from "aos";
import "aos/dist/aos.css";

import "gatsby-plugin-prismic-previews/dist/styles.css";

import "./src/styles/reset.css";
import "./src/styles/common.scss";
import "./src/styles/style.css";

let YT = window.YT || "undefined";
const YTUrl = "https://www.youtube.com/iframe_api";
let Vimeo = window.Vimeo || "undefined";
const VimeoUrl = "https://player.vimeo.com/api/player.js";

window.onYouTubeIframeAPIReady = function () {
  YT = window.YT;
  loadYouTubePlayer();
};

const loadScripts = () => {
  var tag = "";
  var firstScriptTag = "";
  if (typeof YT === "undefined" || typeof YT.Player === "undefined") {
    tag = document.createElement("script");
    tag.src = YTUrl;
    tag.onload = () => {
      // loadYouTubePlayer();
    };
    firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  if (typeof Vimeo === "undefined" || typeof Vimeo.Player === "undefined") {
    tag = document.createElement("script");
    tag.src = VimeoUrl;
    firstScriptTag = document.getElementsByTagName("script")[0];
    tag.onload = () => {
      Vimeo = window.Vimeo;
      loadVimeoPlayer();
    };
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }
};

const loadYouTubePlayer = () => {
  if (typeof window === "undefined" || !window.document) return null;
  if (typeof YT === "undefined" || typeof YT.Player === "undefined")
    return null;

  const allYouTubeIFrames = document.querySelectorAll(".iframe-youtube");
  allYouTubeIFrames.forEach((video) => {
    window.player[video.id] = new YT.Player(video.id, {
      width: 1920,
      height: 1080,
      videoId: video.getAttribute("data-url").split("v=")[1].substring(0, 11),
      playerVars: {
        modestbranding: 1,
        autoPlay: 0,
        playsinline: 0,
        controls: 1,
        fs: 1,
        allowfullscreen: 1,
      },
      events: {
        onReady: (event) => {
          const allowString = event.target.h
            .getAttribute("allow")
            .concat("; fullscreen;");
          event.target.h.setAttribute("allow", allowString);
          event.target.h.setAttribute("allowFullScreen", "allowFullScreen");
          event.target.h.setAttribute(
            "mozallowfullscreen",
            "mozallowfullscreen"
          );
          event.target.h.setAttribute("msallowfullscreen", "msallowfullscreen");
          event.target.h.setAttribute("oallowfullscreen", "oallowfullscreen");
          event.target.h.setAttribute(
            "webkitallowfullscreen",
            "webkitallowfullscreen"
          );
        },
        onStateChange: (event) => {
          onPlayerStateChange(event, video, "youtube", null);
        },
      },
    });
  });
};

const loadVimeoPlayer = () => {
  if (typeof window === "undefined" || !window.document) return null;
  if (typeof Vimeo === "undefined" || typeof Vimeo.Player === "undefined")
    return null;

  const allVimeoIFrames = document.querySelectorAll(".iframe-vimeo");

  allVimeoIFrames.forEach((video) => {
    window.player[video.id] = new Vimeo.Player(video.id, {
      url: video.getAttribute("data-url"),
      responsive: true,
      autopause: true,
      controls: true,
      playsinline: false,
    });
    window.player[video.id].on("pause", (event) => {
      onPlayerStateChange(event, video, "vimeo", "pause");
    });
    window.player[video.id].on("play", (event) => {
      onPlayerStateChange(event, video, "vimeo", "play");
    });
  });
};

const onPlayerStateChange = (event, video, provider, type = "pause") => {
  const overlay = document.querySelector("#overlay-" + video.id);
  const tmpVideo = provider === "youtube" ? window.player[video.id].h : video;

  if (
    (provider === "vimeo" && type === "pause") ||
    (provider === "youtube" && event.data === 2)
  ) {
    tmpVideo.classList.remove("active");
    overlay.classList.add("active");
  } else {
    tmpVideo.classList.add("active");
    overlay.classList.remove("active");
  }
};

function isMyScriptLoaded(url) {
  if (!url) url = "http://xxx.co.uk/xxx/script.js";
  var scripts = document.getElementsByTagName("script");
  for (var i = scripts.length; i--; ) {
    if (scripts[i].src === url) return true;
  }
  return false;
}

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider>{element}</PrismicPreviewProvider>
);

// export const onInitialClientRender = () => {
// };

export const onRouteUpdate = ({ location, prevLocation }) => {
  Aos.init();

  window.player = {};

  if (!isMyScriptLoaded(YTUrl) && !isMyScriptLoaded(VimeoUrl)) {
    loadScripts();
  } else {
    loadYouTubePlayer();
    loadVimeoPlayer();
  }
};
