// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-prismic-page-url-js": () => import("./../../../src/pages/{PrismicPage.url}.js" /* webpackChunkName: "component---src-pages-prismic-page-url-js" */),
  "component---src-pages-projects-prismic-project-uid-js": () => import("./../../../src/pages/projects/{PrismicProject.uid}.js" /* webpackChunkName: "component---src-pages-projects-prismic-project-uid-js" */),
  "component---src-pages-selectors-prismic-selectors-uid-js": () => import("./../../../src/pages/selectors/{PrismicSelectors.uid}.js" /* webpackChunkName: "component---src-pages-selectors-prismic-selectors-uid-js" */)
}

